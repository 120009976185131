import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/sections",
        name: "sections",
        component: () => import("@/views/Sections.vue"),
      },
      {
        path: "/lists",
        name: "lists",
        component: () => import("@/views/Lists.vue"),
      },
      {
        path: "/questions",
        name: "questions",
        component: () => import("@/views/Questions.vue"),
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/Users.vue"),
      },
      {
        path: "/admins",
        name: "admins",
        component: () => import("@/views/Admins.vue"),
      },
      {
        path: "/admin",
        name: "admin-create",
        component: () => import("@/views/AdminCreate.vue"),
      },
      {
        path: "/admin/:id",
        name: "admin",
        component: () => import("@/views/AdminEdit.vue"),
      },
      {
        path: "/me",
        name: "me",
        component: () => import("@/views/Edit.vue"),
      },
      {
        path: "/surveys",
        name: "surveys",
        component: () => import("@/views/Surveys.vue"),
      },
      {
        path: "/regions",
        name: "regions",
        component: () => import("@/views/Regions.vue"),
      },
      {
        path: "/user/:id",
        name: "user",
        component: () => import("@/views/User.vue"),
      },
      {
        path: "/user/:id/details",
        name: "user-detail",
        component: () => import("@/views/UserDetails.vue"),
      },
      {
        path: "/user/:user_id/:page/detail/result/:id",
        name: "user-detail_result",
        component: () => import("@/views/UserDetailsResult.vue"),
      },
      {
        path: "/user/:user_id/:page/detail/survey/:id",
        name: "user-detail_survey",
        component: () => import("@/views/UserDetailsSurvey.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.ME, { api_token: JwtService.getToken() });
  const [errorName] = Object.keys(store.getters.getErrors);
  const error = store.getters.getErrors[errorName];
  if (error) {
    router.push({ name: "sign-in" });
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
